<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="1340px"
    @close="close"
    @open="open"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="标题" prop="name">
        <el-input v-model="form.name" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="内容" prop="content">
        <tinymce v-model="form.content" placeholder="请输入内容" :height="400" :width="1110" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否启用" prop="state">
        <el-switch
            v-model="form.is_active"
            active-color="#13ce66"
            active-text="开启"
            :active-value="1"
            inactive-color="#ff4949"
            inactive-text="禁用"
            :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiAddReplyTemplate, apiGetReplyTemplate} from "@/request/api";

  export default {
    name: 'Edit',
    data() {
      return {
        form: {
          name: '',
          content: '',
          is_active: 1,
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入标题' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    methods: {
      open(){},
      getData(id){
        apiGetReplyTemplate({id:id}).then(res=>{
          if(res.code == 200){
            this.form = res.data
          }
        })
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.form = {
            name: '',
            content: '',
            is_active: 1,
          }
        } else {
          this.title = '修改'
          this.getData(row.id)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            apiAddReplyTemplate(this.form).then(res =>{
              if (res.code == '200') {
                this.$message.success(res.message)
                this.$emit('fetch-data')
                this.close()
              } else {
                this.$message.error(res.message)
              }
            })

          } else {
            return false
          }
        })
      },
    },
  }
</script>
